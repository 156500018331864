
import { defineComponent, ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterOption, FilterType } from '@/components/filter/Index.vue';
import { usePage, useEvents, useDeleteEvent, useKeyword } from '@/composables';
import { DEFAULT_PAGE_SIZE, DELETED_SUCCESSFULLY_TEXT, IdOption, ResponseError } from '@/services/api';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import permissionUnits from '@/components/permission-units/index.vue';
import { Event, EventsStatus, EventsStatusType } from '@/interfaces';

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const eventDateRange = ref<Date[]>(null);
    const status = ref<EventsStatusType>();
    const startedAt = ref<string>(null);
    const endedAt = ref<string>(null);

    const { data, isLoading, isFetching, refetch } = useEvents({ page, pageSize, keyword, startedAt, endedAt, status });
    const { isLoading: isDeleting, mutate: deleteEventById } = useDeleteEvent();

    const statusType = {
      [EventsStatus.SCHEDULED]: '未上架',
      [EventsStatus.PUBLISHED]: '上架中',
      [EventsStatus.REGISTRATION_CLOSED]: '報名截止',
      [EventsStatus.CLOSED]: '已結束'
    };

    const filterOptions = computed<FilterOption[]>(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '狀態',
          placeholder: '請選擇',
          options: Object
            .entries(EventsStatus)
            .map(([label, value]) => ({ label: statusType[value], value }))
        }
      ];
    });

    const handleFilterChange = (event: any) => {
      status.value = event[0] || null;
    };

    const handleDateChange = () => {
      if (!eventDateRange.value) {
        startedAt.value = null;
        endedAt.value = null;
        return;
      }
      startedAt.value = formatISOString(eventDateRange.value[0]);
      endedAt.value = formatISOString(eventDateRange.value[1]);
    };

    const currentDeletedIndex = ref<number>();
    const deleteEvent = ({ id }: IdOption, index: number) => {
      currentDeletedIndex.value = index;

      ElMessageBox.confirm(
        '刪除後活動頁將下架，並且用戶將無法再次進入，活動資料將遺失確定要刪除嗎？',
        '是否刪除此活動資料',
        {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          deleteEventById({ id }, {
            onSuccess() {
              refetch.value();

              ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    const eventDateRangeText = (row: Event) => {
      return formatLocalTime(row.startedAt, 'YYYY-MM-DD') + ' - ' + (row.endedAt === null ? '' : formatLocalTime(row.endedAt, 'YYYY-MM-DD'));
    };

    const routeParams = (row: Event) => {
      return {
        id: row.id,
        name: row.name,
        dateRange: eventDateRangeText(row)
      };
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      filterOptions,
      handleFilterChange,
      eventDateRange,
      handleDateChange,
      keyword,
      setPageQuery,
      currentDeletedIndex,
      deleteEvent,
      isDeleting,
      formatLocalTime,
      statusType,
      eventDateRangeText,
      routeParams
    };
  }
});
